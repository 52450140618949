import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import {TextField, Button, Alert} from "@mui/material";
import axios from 'axios';
import Carousel from 'react-material-ui-carousel';
import Logo from "../../App/Common/Logo";
require('./register.css')

const Register = () =>{
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')
    const navigate = useNavigate();
    const images = [
        '/reg_1.png',
        '/reg_2.png',
        '/reg_3.png'
    ];
    const login = () =>{
        navigate('/login')
    }
    const messageHandler = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
    }
    const submitHandler = (event) =>{
        event.preventDefault()
        setSeverity('info')
        setMessage('Your user is being created...')

        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/register',
            method: 'POST',
            data:{
                name,
                phone,
                email,
                password
            },
            headers:{
                'x-api-key': '6eShxNuQqT7kthZeRwSzj2gfOhpMcOQG1sEaOZ7u',
                'Content-Type': 'application/json'
            }
        }

        axios(config).then(() =>{
            messageHandler('Welcome to our platform! Your account has been created and you can now log in.', 'success')
            setTimeout(() => {
                navigate('/login')
            }, 3000)
        }).catch((error)=>{
            messageHandler('There was an error creating your user', 'error')
        })
    }

    return(
        <Container className={"register_container"} component="main">
            <Box className={"reg_container"}>
                <Box className={"left_container"}>
                    <Box className={"mini_container"}>
                        <img src={'/logo_with_letters.png'} className={"logo"}/>
                        <h1><span className="empty-fill">GET</span> STARTED</h1>
                        <h3>Already have an account? <span onClick={login} className={'register'}>Login here</span></h3>
                    </Box>
                    <Box value={name} component="form" onSubmit={submitHandler}>
                        <TextField className={"text-field"} label="Name" value={name} fullWidth onChange={(event) => setName(event.target.value)} />
                        <TextField className={"text-field"} label="Email" value={email} fullWidth onChange={(event) => setEmail(event.target.value)} />
                        <TextField className={"text-field"} label="Phone" value={phone} fullWidth onChange={(event) => setPhone(event.target.value)} />
                        <TextField className={"text-field"} type="password" label="Password" value={password} fullWidth onChange={(event) => setPassword(event.target.value)} />
                        <Box>
                            <Button className={"register_button"} type="submit">Register</Button>
                        </Box>
                        <Box>
                            <h2>By signing up I agree to the <u>terms of service</u> and the <u>privacy policy.</u></h2>
                        </Box>
                        <Box sx={{marginTop:"30px"}}>
                            {message && <Alert severity={severity}>{message}</Alert>}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className={"car_container"}>
                <Box className={"carousel"}>
                    <Carousel>
                        {images.map((image) => (
                            <img className={"car_image"} src={image} alt="Slide" key={image}/>
                        ))}
                    </Carousel>
                </Box>
            </Box>
        </Container>
    )
}

export default Register;