import React, {useEffect, useState} from 'react'
import {getUser, resetUserSession} from "../../App/services/AuthService";
import {useNavigate} from "react-router-dom";
import MainLayout from "../../App/Layout/MainLayout";
import Header from "../Header/header";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import RateRadio from "../RateRadio/RateRadio";
import * as auth from "../../App/services/AuthService";
import axios from "axios";
require ('./eod.css')

export default function EOD() {
    const [health, setHealth] = React.useState('');
    const [emotional, setEmotional] = React.useState('');
    const [spiritual, setSpiritual] = React.useState('');
    const [money, setMoney] = React.useState('');
    const [mental, setMental] = React.useState('');
    const [family, setFamily] = React.useState('');
    const [productive, setProductive] = React.useState(false);
    const [wins, setWins] = useState("");
    const [project, setProject] = useState("");
    const [subconscious, setSubconscious] = useState("");
    const [happy, setHappy] = React.useState(false);
    const [tomorrow, setTomorrow] = useState("");
    const [disableButton, setDisableButton] = React.useState(false);
    const [User, setUser] = useState('');
    const [message, setMessage] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if(!User){
            setUser(getUser());
        }
    }, [])

    const submitEOD = () => {
        setDisableButton(true);
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/eod',
            method: 'post',
            data: {
                user: User._id,
                productive: productive,
                happy: happy,
                wins: wins,
                project: project,
                subconscious: subconscious,
                health: health,
                emotional: emotional,
                spiritual: spiritual,
                money: money,
                mental: mental,
                family: family,
                tomorrow: tomorrow
            }
        }
        axios(config)
            .then((response) => {
                console.log(response);
                setDisableButton(false);
                setMessage(response.data.message)
            })
            .catch((error) => {
                console.log(error);
                setDisableButton(false);
                setError("there was a problem saving your EOD, try again later")
            });

    }

    const handleWinsChange = (event) => {
        setWins(event.target.value);
    };

    const handleProjectChange = (event) => {
        setProject(event.target.value);
    }

    const handleSubconsciousChange = (event) => {
        setSubconscious(event.target.value);
    }

    const handleTomorrowChange = (event) => {
        setTomorrow(event.target.value);
    }
    const handleValueChange = (variableName, value) => {
        switch (variableName) {
            case 'health':
                setHealth(value);
                break;
            case 'emotional':
                setEmotional(value);
                break;
            case 'spiritual':
                setSpiritual(value);
                break;
            case 'money':
                setMoney(value);
                break;
            case 'mental':
                setMental(value);
                break;
            case 'family':
                setFamily(value);
                break;
            default:
                break;
        }
    };

    return(
        <MainLayout>
            <Header pretitle="End" title="of Day" sub_title="How did your day go?" image={`${process.env.PUBLIC_URL}/header_habit.png`} />
            <Box className="eod-container" sx={{
                display: 'flex',
                flexWrap: 'wrap',
            }}>
                <Box className="eod-box">
                    <Typography className="eod-h4" variant="h4" component="h4" gutterBottom>Was I productive?</Typography>
                    <Button  className={productive ? "btn-green" : "btn-gray"} variant="contained" onClick={() => setProductive(true)}>YES</Button>
                    <Button  className={!productive ? "btn-red" : "btn-gray"} variant="contained" onClick={() => setProductive(false)} >NO</Button>
                </Box>
                <Box className="eod-box">
                    <Typography className="eod-h4" variant="h4" component="h4" gutterBottom>Was I happy?</Typography>
                    <Button className={happy ? "btn-green" : "btn-gray"} variant="contained" onClick={() => setHappy(true)}>YES</Button>
                    <Button className={!happy ? "btn-red" : "btn-gray"} variant="contained" onClick={() => setHappy(false)} >NO</Button>
                </Box>
            </Box>
            <Box className="eod-container"  sx={{
                display: 'flex',
                flexWrap: 'wrap',
                marginTop:'0px',
                marginBottom: '30px',
            }}>
                <Box className="eod-box">
                    <Typography className="eod-h4" variant="h4" component="h4" gutterBottom>Did you have any wins today?</Typography>
                    <TextField sx={{
                        width: '100%',
                    }} onChange={handleWinsChange} id="wins" label="Answer here" variant="outlined" />
                </Box>
                <Box className="eod-box">
                    <Typography className="eod-h4" variant="h4" component="h4" gutterBottom>What big projects did you move forward today?</Typography>
                    <TextField sx={{
                        width: '100%',
                    }} onChange={handleProjectChange} id="projects" label="Answer here" variant="outlined" />
                </Box>
                <Box className="eod-box">
                    <Typography className="eod-h4" variant="h4" component="h4" gutterBottom>What do I want my subconscious to think about / solve tonight?</Typography>
                    <TextField sx={{
                        width: '100%',
                    }} onChange={handleSubconsciousChange} id="subconscious" label="Answer here" variant="outlined" />
                </Box>
            </Box>
            <Box className="eod-container" sx={{
                position: 'relative',
                width: '100%',
            }}>
                <Box className="eod_rate_container" sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}>
                    <FormControl sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row'
                    }}>
                        <RateRadio onValueChange={handleValueChange} variableName="health" title="Health and physicality" />
                        <RateRadio onValueChange={handleValueChange} variableName="emotional" title="Emotional well-being" />
                        <RateRadio onValueChange={handleValueChange} variableName="spiritual" title="Spiritual alignment" />
                        <RateRadio onValueChange={handleValueChange} variableName="money" title="Money and finance" />
                        <RateRadio onValueChange={handleValueChange} variableName="mental" title="Mental fortitude" />
                        <RateRadio onValueChange={handleValueChange} variableName="family" title="Family" />
                        <Box sx={{
                            width: '100%',
                        }}>
                            <Typography  className="eod-h4" variant="h4" component="h4" gutterBottom>What do I need to do tomorrow to improve these numbers?</Typography>
                            <TextField sx={{
                                width: '25%',
                            }} className="last_eod" onChange={handleTomorrowChange} id="outlined-basic" label="Answer here" variant="outlined" />
                        </Box>
                    </FormControl>
                </Box>
                <Button disabled={disableButton} onClick={() => {
                    submitEOD();
                }} className='button' variant="contained">Submit -></Button>
                {message &&
                    (
                        <Alert sx={{
                            width: '25%',
                            marginTop: '20px',
                        }} severity="success">{message}</Alert>
                    )
                }
                {error && (
                    <Alert severity="error">{error}</Alert>
                )}
            </Box>

        </MainLayout>
    )
}