import { FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";

export default function RateRadio(props) {
    const handleChange = (event) => {
        props.onValueChange(props.variableName, event.target.value);
    };

    return (
        <Box className="radio-box" >
            <FormLabel>{props.title}</FormLabel>
            <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name={props.name}
                defaultValue="top"
                onChange={handleChange}
            >
                <FormControlLabel
                    sx={{margin:0}}
                    value="1"
                    control={<Radio className="eod_radio_individual" />}
                    label="1"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="2"
                    control={<Radio className="eod_radio_individual" />}
                    label="2"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="3"
                    control={<Radio className="eod_radio_individual" />}
                    label="3"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="4"
                    control={<Radio className="eod_radio_individual" />}
                    label="4"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="5"
                    control={<Radio className="eod_radio_individual" />}
                    label="5"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="6"
                    control={<Radio className="eod_radio_individual" />}
                    label="6"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="7"
                    control={<Radio className="eod_radio_individual" />}
                    label="7"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="8"
                    control={<Radio className="eod_radio_individual" />}
                    label="8"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="9"
                    control={<Radio className="eod_radio_individual" />}
                    label="9"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    sx={{margin:0}}
                    value="10"
                    control={<Radio className="eod_radio_individual" />}
                    label="10"
                    labelPlacement="bottom"
                />
            </RadioGroup>
        </Box>
    );
}
