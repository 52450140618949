export const getUser = () => {
    const user = sessionStorage.getItem('user');
    if (user === 'undefined' || !user) {
        return null;
    } else {
        return JSON.parse(user);
    }
}
export const getToken = () => {
    return sessionStorage.getItem('token');
}

export const setUserData = (data) => {
    sessionStorage.setItem('user', JSON.stringify(data.user));
    sessionStorage.setItem('token', data.token);
}

export const updateUserData = (user) => {
    sessionStorage.removeItem('user');
    sessionStorage.setItem('user', JSON.stringify(user));
}

export const resetUserSession = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('token');
}