import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import {TextField, Button, Alert} from "@mui/material";
import axios from 'axios';
import {setUserData,getUser} from "../../App/services/AuthService";
import Logo from "../../App/Common/Logo";
require('./login.css')

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('info')
    const navigate = useNavigate();

    const messageHandler = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
    }
    const register = () => {
        navigate('/register')
    }
    const submitHandler = (event) => {
        event.preventDefault();
        if (email.trim() === '' || password.trim() === '') {
            messageHandler('Both username and password are required', 'error');
            return;
        }
        messageHandler('Logging in...', 'info');
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/login',
            method: 'post',
            data: {
                email: email,
                password: password
            },
            headers: {
                'x-api-key': '6eShxNuQqT7kthZeRwSzj2gfOhpMcOQG1sEaOZ7u',
                'Content-Type': 'application/json'
            }
        }

        axios(config)
            .then((response) => {
                setUserData(response.data);
                messageHandler('Login successful', 'success');
                setTimeout(() => {
                    navigate('/')
                }, 1000)
            })
            .catch((error) => {
                if (error.response.status === 401 || error.response.status === 403) {
                    messageHandler('Invalid username or password', 'error');
                } else {
                    messageHandler(error.response.data.message, 'error');
                }
            });
    }
        return (
            <Box className={'loginBackground'} sx={{backgroundImage: `url(${process.env.PUBLIC_URL}/Login.jpg)`}}>
                <Container  component="main"
                            sx={{marginTop:'80px', maxWidth:'700px !important'}}>
                    <img className='login-logo' src='/logo_with_letters.png' />
                    <Box className={'loginBox'} sx={{
                        marginTop: 8,
                    }}>
                        <h1 className="welcome-login"><span class="empty-fill">Welcome</span> back!</h1>
                        <h3>Don’t have an account? <span onClick={register} className={'register'}>Register here</span></h3>
                        <Box component="form" noValidate onSubmit={submitHandler}
                             sx={{
                                 width: '100%',
                                 mt:4,
                             }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(event) => setEmail(event.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(event) => setPassword(event.target.value)}
                            />
                            <Button
                                className={'loginButton'}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{mt: 3, mb: 2}}
                            >
                                Sign in
                            </Button>
                            {message && <Alert severity={severity}>{message}</Alert>}
                        </Box>
                    </Box>
                </Container>
            </Box>

        )

}