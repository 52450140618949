import React from 'react'
import Box from '@mui/material/Box';
require ('./header.css')
export default function Header(props) {
  return (
    <Box className={'headerContainer'}>
        <Box className='h_left'>
            <Box className='left_container' >
                <h1><span className="empty-fill" >{props.pretitle}</span> {props.title}</h1>
                <h3>{props.sub_title}</h3>
            </Box>
        </Box>
        <Box className='h_right'>
            <img src={props.image} />
        </Box>
    </Box>
  );
}