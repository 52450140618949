import React from 'react';
import Box from "@mui/material/Box";
require('./DateBox.css')

export default function DateBox(props) {
    return (
        <Box className={props.type}>
            <Box className="date-box__date">{props.date}</Box>
            <Box className="date-box__num">{props.num}</Box>
        </Box>
    )
}