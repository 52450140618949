import React from 'react';
import Box from "@mui/material/Box";
import DateBox from "../DateBox/DateBox";
require ('./DateBoxContainer.css')
export default function DateBoxContainer(props) {
    const dates = props.data.rdata
    const dateBoxes = dates.map((data, index) => {
        return <DateBox key={index} date={data.dayName} num={data.dayNumber} type={index === dates.length - 1 ? "primary" : "regular"} />
    });
    return (
        <Box className='container'>
            <Box className='spacer'>

            </Box>
            <Box className='week-box'>
                {dateBoxes}
            </Box>

        </Box>
    )
}