import React, {useEffect, useState} from 'react'
import {getUser} from "../../App/services/AuthService";
import MainLayout from "../../App/Layout/MainLayout";
import Header from "../Header/header";
import {Accordion, AccordionDetails, AccordionSummary, LinearProgress} from "@mui/material";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Box from "@mui/material/Box";
import {Skeleton} from "@mui/lab";
require ('./history.css')

export default function EOD() {
    const [User, setUser] = useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [ratings, setRatings] = useState([]);
    const [eod, setEod] = useState({});

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        if(!User){
            setUser(getUser());
        }
        if(User){
            getRatings();
            getEOD();
        }
    }, [User])

    const getEOD = () => {
        const config = {
                url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/eod?user=' + User._id,
                method: 'get'
        }
        axios(config)
            .then((response) => {
                    console.log('eod',response);
                    setEod(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const getRatings = () => {
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/rating?user=' + User._id,
            method: 'get'
        }
        axios(config)
            .then((response) => {
                console.log(response);
                setRatings(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return(
        <MainLayout>
            <Header pretitle="habits" title="history" sub_title="See your previous activity" image={`${process.env.PUBLIC_URL}/header_history.png`} />
            {!ratings && !eod && (
                <>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation={false} />
                    <Skeleton animation="wave" />

                    <Skeleton width="100%" />
                </>
            )}
            {ratings && eod && (
                ratings.map((rating, index) => {
                    const createdAtDate = new Date(rating.createdAt);
                    const correspondingEOD = Array.isArray(eod) ? eod.find(rating => {
                        const eodCreatedAt = new Date(rating.createdAt);
                        return eodCreatedAt.toDateString() === createdAtDate.toDateString();
                    }) : [];
                    const formattedCreatedAt = createdAtDate.toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    });
                    return(
                        <Accordion className="history-accordion" sx={{
                            marginTop: '25px',
                            marginBottom: '25px',
                            border: '1px solid #E8E8E8',
                            borderRadius: '5px',
                            boxShadow: 'none',
                        }} key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                            >
                                <CalendarTodayIcon />
                                <Typography className="his_date" sx={{
                                    width: '33%',
                                    flexShrink: 0,
                                    paddingLeft: '10px'
                                }}>
                                    {formattedCreatedAt}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <Box
                                        sx={{
                                            textAlign: 'right',
                                            transform: `translateX(-${100 - rating.score}%)`,
                                            color: rating.score >= 80 ? '#00BFA6' : rating.score >= 40 ? '#FFC107' : '#FF5722',
                                            fontFamily: 'Hurme-Black',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {rating.score}%
                                    </Box>

                                    <LinearProgress
                                        sx={{
                                            backgroundColor: '#E8E8E8',
                                        }}
                                        className={rating.score >= 80 ? 'green-progress' : rating.score >= 40 ? 'yellow-progress' : 'red-progress'}
                                        variant="determinate"
                                        value={rating.score}
                                    />
                                    {correspondingEOD && (
                                        <Box>
                                            <Box className="eod-basic">
                                                <Box className="basic">
                                                    <h3>Was I productive?</h3>
                                                    <h4>{correspondingEOD.productive ? 'Yes' : 'No'}</h4>
                                                </Box>
                                                <Box className="basic">
                                                    <h3>Was I happy:</h3>
                                                    <h4>{correspondingEOD.happy ? 'Yes' : 'No'}</h4>
                                                </Box>
                                                <Box className="basic">
                                                    <h3>Did you have any wins today?</h3>
                                                    <h4>{correspondingEOD.wins}</h4>
                                                </Box>
                                                <Box className="basic">
                                                    <h3>What big projects did you move forward today?</h3>
                                                    <h4>{correspondingEOD.project}</h4>
                                                </Box>
                                                <Box className="basic">
                                                    <h3>What do I want my subconscious to think about / solve tonight?</h3>
                                                    <h4>{correspondingEOD.subconscious}</h4>
                                                </Box>

                                            </Box>
                                            <Box className="eod-extra">
                                                <Box className="extra">
                                                    <h3>Health and physicality:</h3>
                                                    <h4>{correspondingEOD.health}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>Emotional well-being</h3>
                                                    <h4>{correspondingEOD.emotional}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>Spiritual alignment</h3>
                                                    <h4>{correspondingEOD.spiritual}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>Money and finance</h3>
                                                    <h4>{correspondingEOD.money}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>Mental fortitude</h3>
                                                    <h4>{correspondingEOD.mental}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>Family</h3>
                                                    <h4>{correspondingEOD.family}</h4>
                                                </Box>
                                                <Box className="extra">
                                                    <h3>What do I need to do tomorrow to improve these numbers?</h3>
                                                    <h4>{correspondingEOD.tomorrow}</h4>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    )
                })
            )}
        </MainLayout>
    )
}