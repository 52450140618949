import React, {useEffect, useState} from 'react'
import {getUser, resetUserSession, setUserData} from "../../App/services/AuthService";
import {useNavigate} from "react-router-dom";
import MainLayout from "../../App/Layout/MainLayout";
import Header from "../Header/header";
import Box from "@mui/material/Box";
import DateBoxContainer from "../DateBoxContainer/DateBoxContainer";
import HabitContainer from "../HabitContainer/HabitContainer";
import axios from "axios";
import * as auth from "../../App/services/AuthService";
import {Button} from "@mui/material";
import CircularProgressWithLabel from "../CircularProgressWithLabel/CircularProgressWithLabel";
import Typography from "@mui/material/Typography";
import {Skeleton} from "@mui/lab";
require('./home.css')

export default function Home() {
    const navigate = useNavigate();
    const [Week, setWeek] = useState('');
    const [WeekInfo, setWeekInfo] = useState('');
    const [User, setUser] = useState('');
    const [score, setScore] = useState(0);
    const [scoreColor, setScoreColor] = useState('score-green');
    const [show, setShow] = useState(true);
    const [hide, setHide] = useState(false);
    const [back, setBack] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const user = auth.getUser();
    const logout = () => {
        resetUserSession()
        navigate('/login')
    }
    useEffect(() => {
         if(!Week){
            weekHandler();
         }
         if(!User){
             setUser(getUser());
         }
    }, [Week])

    const submitHabit = () => {
        setDisableButton(true);
        let answer1 = localStorage.getItem("q-state-1");
        let answer2 = localStorage.getItem("q-state-2");
        let answer3 = localStorage.getItem("q-state-3");
        let answer4 = localStorage.getItem("q-state-4");
        let answer5 = localStorage.getItem("q-state-5");

        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/rating',
            method: 'post',
            data: {
                user: User._id,
                q1: answer1,
                q2: answer2,
                q3: answer3,
                q4: answer4,
                q5: answer5
            }
        }

        axios(config)
            .then((response) => {
                setScoreColor(response.data.scoreColor);
                setShow(false);
                setHide(true);
                setTimeout(() => {
                    setScore(response.data.score);
                }, 250);
                weekHandler();
                setTimeout(() => {
                    setBack(true);
                }, 2000);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const weekHandler = () => {
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/week',
            method: 'post',
            data: {
                user: user._id
            },
            headers: {
                'x-api-key': '6eShxNuQqT7kthZeRwSzj2gfOhpMcOQG1sEaOZ7u',
                'Content-Type': 'application/json'
            }
        };
        axios(config)
            .then((response) => {
                setWeek(response.data);
                console.log(response.data);
                const filteredData = {
                    rdata: response.data.rdata.map((item) => ({
                        dayNumber: item.dayNumber,
                        dayName: item.dayName
                    }))
                };
                setWeekInfo(filteredData);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return(
        <MainLayout>
            <Header pretitle="your" title="habits" sub_title="Tap on the empty square to track your habits." image={`${process.env.PUBLIC_URL}/header_habit.png`} />
            {show && (
                <Box className='HomeContainer'>
                    <Box className='HomeBox'>
                        {WeekInfo && (
                            <DateBoxContainer data={WeekInfo} />
                        )}

                        {!Week && (
                            <>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton animation="wave" />

                                <Skeleton width="100%" />
                            </>
                        )}
                        {Week && (
                            <HabitContainer data={Week} />
                        )}
                        <Button disabled={disableButton} onClick={() => {
                            submitHabit();
                        }} className='button' variant="contained">Submit -></Button>
                    </Box>
                </Box>
            )}
            {hide && (
                <Box className="resultContainer">
                    <CircularProgressWithLabel scorecolor={scoreColor} value={score} />
                    <Typography sx={{
                        fontSize: 60,
                        marginTop: '40px'
                    }} className="today_result" variant="caption" component="div" color="text.secondary">
                        Today's Result
                    </Typography>
                    {back && (
                        <Typography onClick={() => {
                            setShow(true);
                            setHide(false);
                            setBack(false);
                            setDisableButton(false);
                        }} sx={{
                            fontSize: 15,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                            marginTop: '40px'
                        }} className="back_dashboard" variant="caption" component="div" color="text.secondary">
                            Back to dashboard
                        </Typography>
                    )}
                </Box>
            )}

        </MainLayout>
    )
}