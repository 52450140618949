import React from 'react';
import Box from "@mui/material/Box";
import AnswerBox from "../AnswerBox/AnswerBox";
require('./HabitContainer.css')

export default function HabitContainer(props) {
    const week = props.data;
    return(
        <Box className='tracker-container'>
            <Box className='habit-details' >
                <Box className="HabitTitleContainer">
                    <h1 className="HabitTitle">Did I start my day with morning formula?</h1>
                </Box>
                <Box className="HabitTitleContainer">
                    <h1 className="HabitTitle">Did I publish content for my ideal audience today?</h1>
                </Box>
                <Box className="HabitTitleContainer">
                    <h1 className="HabitTitle">Did I exercise or move my body today?</h1>
                </Box>
                <Box className="HabitTitleContainer">
                    <h1 className="HabitTitle">Did I read or study for 45 minutes today?</h1>
                </Box>
                <Box className="HabitTitleContainer">
                    <h1 className="HabitTitle">Did I wake up when I said I was going to wake up today?</h1>
                </Box>
            </Box>
            <Box className='habit-boxes'>
                <Box className="HabitRow">
                    {week && week.rdata.map((item, index) => {
                        const isLastItem = index === week.rdata.length - 1;
                        return (
                            <AnswerBox
                                key={item.dayNumber}
                                qNumber={1}
                                q={item.q1}
                                color={item.q1Color}
                                containerType={isLastItem ? "SquareContainerToday" : "SquareContainerRegular"}
                                state={item.q1}
                            />
                        );
                    })}
                </Box>
                <Box className="HabitRow">
                    {week && week.rdata.map((item, index) => {
                        const isLastItem = index === week.rdata.length - 1;
                        return (
                            <AnswerBox
                                key={item.dayNumber}
                                qNumber={2}
                                q={item.q2}
                                color={item.q2Color}
                                containerType={isLastItem ? "SquareContainerToday" : "SquareContainerRegular"}
                                state={item.q2}
                            />
                        );
                    })}
                </Box>
                <Box className="HabitRow">
                    {week && week.rdata.map((item, index) => {
                        const isLastItem = index === week.rdata.length - 1;
                        return (
                            <AnswerBox
                                key={item.dayNumber}
                                qNumber={3}
                                q={item.q3}
                                color={item.q3Color}
                                containerType={isLastItem ? "SquareContainerToday" : "SquareContainerRegular"}
                                state={item.q3}
                            />
                        );
                    })}
                </Box>
                <Box className="HabitRow">
                    {week && week.rdata.map((item, index) => {
                        const isLastItem = index === week.rdata.length - 1;
                        return (
                            <AnswerBox
                                key={item.dayNumber}
                                qNumber={4}
                                q={item.q4}
                                color={item.q4Color}
                                containerType={isLastItem ? "SquareContainerToday" : "SquareContainerRegular"}
                                state={item.q4}
                            />
                        );
                    })}
                </Box>
                <Box className="HabitRow">
                    {week && week.rdata.map((item, index) => {
                        const isLastItem = index === week.rdata.length - 1;
                        return (
                            <AnswerBox
                                key={item.dayNumber}
                                qNumber={5}
                                q={item.q5}
                                color={item.q5Color}
                                containerType={isLastItem ? "SquareContainerToday" : "SquareContainerRegular"}
                                state={item.q5}
                            />
                        );
                    })}
                </Box>

            </Box>
        </Box>
    )
}