import React, {useEffect, useState} from 'react'
import {getUser, resetUserSession, updateUserData} from "../../App/services/AuthService";
import MainLayout from "../../App/Layout/MainLayout";
import Header from "../Header/header";
import axios from "axios";
import Box from "@mui/material/Box";
import {Alert, Button, LinearProgress, Modal, TextField} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import CircularProgress from "@mui/material/CircularProgress";
import * as auth from "../../App/services/AuthService";
import {useNavigate} from "react-router-dom";

require ('./profile.css');

export default function Profile() {
    const [average, setAverage] = useState(0);
    const [User, setUser] = useState('');
    const [open, setOpen] = React.useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        if(!User){
            setUser(getUser());
        }
        if(User){
            getEOD();
        }
    }, [User])

    const updateProfile = () => {
        setLoading(true);
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/reset',
            method: 'post',
            data: {
                "user": User._id,
                "name": name,
                "email": email,
                "phone": phone,
                "password": password
            }
        }
        axios(config)
            .then((response) => {
                console.log(response);
                setUser(response.data);
                setLoading(false);
                auth.updateUserData(response.data.user);
                setMessage('Profile updated successfully');
                setTimeout(() => {
                    navigate('/')
                }, 1000);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const handleNameChange = (event) => {
        setName(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }
    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }
    const getEOD = () => {
        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/average',
            method: 'post',
            data: {
                "user": User._id
            }
        }
        axios(config)
            .then((response) => {
                setLoading(false);
                setAverage(response.data.average);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    return(
        <MainLayout>
            <Header pretitle="your" title="dashboard" sub_title="Take control over your details" image={`${process.env.PUBLIC_URL}/header_profile.png`} />
            <Box className="profile-container" >
                <Box className="prof_video_container" sx={{
                    width: '50%',
                }}>
                    <img className="video" src="/video_placeholder.jpg" />
                </Box>
                <Box className="profile">
                    <Box className="profile-inner">
                        <h2>Hello, {User.name}</h2>
                        <h4>Update your profile</h4>
                        <Box className="profile-form" sx={{
                            marginTop: '20px',
                        }}>
                            <Box className="profile-form-inner">
                                <Box className="profile-detail" >
                                    <PersonIcon />
                                    <Box sx={{
                                        marginLeft: '20px',
                                    }}>
                                        <h4>{User.name}</h4>
                                    </Box>
                                </Box>
                                <Box className="profile-detail" >
                                    <EmailIcon />
                                    <Box sx={{
                                        marginLeft: '20px',
                                    }}>
                                        <h4>{User.email}</h4>
                                    </Box>
                                </Box>
                                <Box className="profile-detail" >
                                    <LocalPhoneIcon />
                                    <Box sx={{
                                        marginLeft: '20px',
                                    }}>
                                        <h4>{User.phone}</h4>
                                    </Box>
                                </Box>
                                <Box className="profile-detail" >
                                    <LockPersonIcon />
                                    <Box sx={{
                                        marginLeft: '20px',
                                    }}>
                                        <h4>********</h4>
                                    </Box>
                                </Box>

                            </Box>
                            <Box>
                                <img onClick={() => handleOpen()} className="edit-icon" src="/edit.png" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="average-container">
                {average && (
                    <Box>
                        <h2>Your average score</h2>
                        <h4>Your average daily rating score is {average}%, congrats!</h4>
                        <Box
                            sx={{
                                textAlign: 'right',
                                transform: `translateX(-${100 - average}%)`,
                                color: average >= 80 ? '#00BFA6' : average >= 40 ? '#FFC107' : '#FF5722',
                                fontFamily: 'Hurme-Black',
                                fontWeight: 'bold',
                            }}
                        >
                            {average}%
                        </Box>
                        <LinearProgress
                            sx={{
                                backgroundColor: '#E8E8E8',
                            }}
                            className={average >= 80 ? 'green-progress' : average >= 40 ? 'yellow-progress' : 'red-progress'}
                            variant="determinate"
                            value={average}
                        />
                    </Box>
                )}
            </Box>

            <Modal className="profile-modal"
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="profile_modal" sx={style}>
                    <h1><span className="empty-fill" >EDIT</span> PROFILE</h1>
                    <h4>Edit your profile details bellow</h4>
                    <Box  className="profile-form-modal">
                        <Box className="profile-form-inner">
                            <Box className="profile-detail-modal" >
                                <TextField sx={{
                                    width: '100%',
                                }} id="name" onChange={handleNameChange} placeholder={User.name} label="Your Name" variant="outlined" />
                            </Box>
                            <Box className="profile-detail-modal" >
                                <TextField sx={{
                                    width: '100%',
                                }} id="email" onChange={handleEmailChange} placeholder={User.email} label="Your Email" variant="outlined" />
                            </Box>
                            <Box className="profile-detail-modal" >
                                <TextField sx={{
                                    width: '100%',
                                }} id="phone" onChange={handlePhoneChange} placeholder={User.phone} label="Your Phone" variant="outlined" />
                            </Box>
                            <Box className="profile-detail-modal" >
                                <TextField sx={{
                                    width: '100%',
                                }} id="password" onChange={handlePasswordChange} placeholder="***********" label="Your Password" variant="outlined" />
                            </Box>
                        </Box>
                        <Box sx={{
                            display: 'flex',
                        }}>

                            <Button onClick={() => handleClose()} sx={{
                                width: '100%',
                            }} className="btn-gray" variant="contained">CANCEL</Button>
                            <Button onClick={() => updateProfile()} sx={{
                                width: '100%',
                                marginTop: '20px',
                            }} className="btn-green" variant="contained">SAVE</Button>


                        </Box>
                        {loading && (
                            <Box sx={{
                                width: '100%',
                                marginTop: '20px',
                            }}>
                                <CircularProgress />
                            </Box>
                        )}
                        {message &&
                            (
                                <Alert sx={{
                                    width: '95%',
                                    margin: '0 auto',
                                    marginTop: '20px',
                                }} severity="success">{message}</Alert>
                            )
                        }
                    </Box>
                </Box>

            </Modal>
        </MainLayout>
    )
}