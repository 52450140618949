import Register from "./components/Register/register";
import { Route, Routes } from 'react-router-dom';
import Box from '@mui/material/Box';
import Login from "./components/Login/login";
import Home from "./components/Home/home";
import { getUser, getToken, resetUserSession } from "../src/App/services/AuthService";
import { Navigate } from "react-router-dom";
import PropTypes from 'prop-types';
import {useEffect} from "react";
import axios from 'axios';
import EOD from "./components/EOD/eod";
import History from "./components/History/history";
import Profile from "./components/Profile/profile";

const PrivateRoute = ({ children }) => {
    return getUser() ? children : <Navigate to="/login" />;
}

PrivateRoute.propTypes = {
    children: PropTypes.node.isRequired
}
export default function App() {
    document.title = "The Arena App";
    useEffect(() => {
        const token = getToken();
        if (token === 'undefined' || token === undefined || token === null || !token) {
            resetUserSession();
            return;
        }

        const config = {
            url: 'https://b7kvaiwg0f.execute-api.us-east-2.amazonaws.com/Default/verify',
            method: 'post',
            data: {
                email: getUser().email,
                token: token
            },
            headers: {
                'x-api-key': '6eShxNuQqT7kthZeRwSzj2gfOhpMcOQG1sEaOZ7u',
                'Content-Type': 'application/json'
            }
        }

        axios(config)
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                resetUserSession();
            });

    }, [])
  return (
      <Box>
          <Routes>
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route path={"/"} element={
                  <PrivateRoute>
                      <Home />
                  </PrivateRoute>} >
              </Route>
              <Route path={"/eod"} element={
                  <PrivateRoute>
                      <EOD />
                  </PrivateRoute>} >
              </Route>
              <Route path={"/history"} element={
                  <PrivateRoute>
                      <History />
                  </PrivateRoute>} >
              </Route>
              <Route path={"/profile"} element={
                  <PrivateRoute>
                      <Profile />
                  </PrivateRoute>} >
              </Route>
          </Routes>
      </Box>
  );
}

