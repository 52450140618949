import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MenuDrawer from '../Common/MenuDrawer';
import PropTypes from 'prop-types';

export default function MainLayout({ children }) {
    return (
        <Box sx={{ display: 'flex', width: '100%' }}>
            <MenuDrawer />
            <Box component="main" sx={{ width: '100%' }}>
                <Container sx={{ maxWidth: '100% !important' }}>
                    <Box component="main" sx={{ paddingTop: '40px' }}>
                        {children}
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

MainLayout.propTypes = {
    children: PropTypes.node.isRequired
}