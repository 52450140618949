import React, {useEffect} from "react";
import {useState} from 'react'
import Box from "@mui/material/Box";
require("./AnswerBox.css");


export default function AnswerBox(props) {

    const [fillColor, setFillColor] = useState(props.color === "green" ? "#1675E5" : "#E2E2E2");
    const [className, setClassName] = useState(props.color === "green" ? "green" : "gray");

    const [qState, setState] = useState({qNumber: props.qNumber, q: props.q});

    useEffect(() => {
        localStorage.setItem("q-state-"+props.qNumber, props.q);
        let q = localStorage.getItem("q-state-"+props.qNumber);
    }, [])

    const handleClick = (key) => {
        if (qState.qNumber === key && qState.q === true) {
            localStorage.setItem("q-state-"+key, false);
            setState({qNumber: key, q: false});
            setClassName("gray")
            setFillColor("#E2E2E2")
        } else {
            localStorage.setItem("q-state-"+key, true);
            setState({qNumber: key, q: true});
            setClassName("green")
            setFillColor("#1675E5")
        }
    }

    return(
        <Box className={props.containerType}>
            {props.containerType === "SquareContainerToday" ?
            <Box onClick={() => {
                handleClick(props.qNumber);
            }} className={className}>
                <svg className="checkmarkSVG" width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.77395 12.7447L0.248949 7.07895C-0.082983 6.73856 -0.082983 6.18667 0.248949 5.84625L1.451 4.61354C1.78293 4.27312 2.32116 4.27312 2.65309 4.61354L6.375 8.43021L14.3469 0.25529C14.6788 -0.0850966 15.2171 -0.0850966 15.549 0.25529L16.7511 1.48799C17.083 1.82838 17.083 2.38028 16.7511 2.7207L6.97604 12.7447C6.64408 13.0851 6.10589 13.0851 5.77395 12.7447Z" fill={fillColor}/>
                </svg>
            </Box> :
            <Box className={props.color} >
            </Box>
            }
        </Box>
    )
}
