import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Logo from './Logo';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';
import { resetUserSession } from '../services/AuthService';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
require('./MenuDrawer.css')

const drawerWidth = 250;

export default function MenuDrawer() {
    const navigate = useNavigate();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [homeIconColor, setHomeIconColor] = React.useState('white');
    const [eodIconColor, setEODIconColor] = React.useState('white');
    const [profileIconColor, setProfileColor] = React.useState('white');
    const [historyIconColor, setHistoryColor] = React.useState('white');
    const [logoutIconColor, setLogOutColor] = React.useState('white');

    const handleDrawerToggle = () => {
        console.log('handleDrawerToggle');
        setMobileOpen(!mobileOpen);
    };

    const changeColor = (id) => {
        switch (id) {
            case 'home':
                setHomeIconColor('#85b4cd');
                return;
            case 'eod':
                setEODIconColor('#85b4cd');
                return;
            case 'profile':
                setProfileColor('#85b4cd');
                return;
            case 'history':
                setHistoryColor('#85b4cd');
                return;
            case 'logout':
                setLogOutColor('#85b4cd');
                return;
        }
    };

    const logoutHandler = () => {
        resetUserSession();
        navigate('/login');
    };
    const historyHandler = () => {
        navigate('/history');
    };
    const homeHandler = () => {
        navigate('/');
    };

    const eodHandler = () => {
        navigate('/eod');
    }

    const profileHandler = () => {
        navigate('/profile');
    };

    const drawer = (
        <div className="menudrawer" style={{backgroundColor:'black', height:'100%'}}>
            <Toolbar />
            <img className="drawer_logo" src="/logo_small.png" />
            <List className={"ubuntu"} sx={{ backgroundColor: 'black', width: '100%', height: 'calc(100vh - 200px)', display:'flex', flexWrap: 'wrap', alignContent: 'center', position: 'relative' }}>
                <ListItem key={'Home'} sx={{ paddingBottom: '0px' }}>
                    <ListItemButton
                        sx={{ paddingBottom: '0px' }}
                        onClick={homeHandler}
                        onPointerOver={() => changeColor('home')}
                        onPointerLeave={() => setHomeIconColor('white')}>
                        <div style={{display:'flex'}}>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11 1L14.09 7.26L21 8.27L16 13.14L17.18 20.02L11 16.77L4.82 20.02L6 13.14L1 8.27L7.91 7.26L11 1Z" stroke={homeIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <ListItemText className={"widget-title"} sx={{ color: homeIconColor }} primary={"Today's Rating"} />
                        </div>
                    </ListItemButton>
                </ListItem>
                <ListItem key={'eod'} sx={{ paddingBottom:'0px'}}>
                    <ListItemButton
                        sx={{ paddingBottom: '0px' }}
                        onClick={eodHandler}
                        onPointerOver={() => changeColor('eod')}
                        onPointerLeave={() => setEODIconColor('white')}>
                        <div style={{display:'flex'}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.008 24C10.416 24 7.824 23.136 5.712 21.528C2.88 19.416 1.152 16.08 1.008 12.504C0.768004 8.18397 3.12 3.98397 6.816 1.75197C8.928 0.503971 11.28 -0.120029 13.752 0.0239713C14.376 0.0239713 15 0.527971 15.12 1.00797C15.36 1.63197 15.24 2.25597 14.76 2.73597C12.792 4.70397 12.048 7.43997 12.528 10.152C13.392 14.112 17.112 16.824 21.192 16.464C21.816 16.464 22.44 16.704 22.8 17.328C23.16 17.832 23.04 18.576 22.68 19.056C20.952 21.408 18.36 23.136 15.504 23.76C14.736 23.88 13.872 24 13.008 24ZM11.784 2.61597C10.536 2.73597 9.312 3.23997 8.064 3.98397C5.088 5.71197 3.36 9.04797 3.48 12.384C3.6 15.216 4.968 17.832 7.2 19.56C9.432 21.288 12.264 21.912 15 21.288C16.728 20.928 18.216 20.184 19.44 18.936C15 18.576 11.16 15.216 10.056 10.656C9.552 7.91997 10.176 4.96797 11.784 2.61597Z"   fill={eodIconColor}/>
                            </svg>

                            <ListItemText className={"widget-title"} sx={{ color: eodIconColor }} primary={"End of Day"} />
                        </div>
                    </ListItemButton>
                </ListItem>
                <ListItem key={'History'} sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <ListItemButton
                        onClick={historyHandler}
                        sx={{ paddingBottom: '0px' }}
                        onPointerOver={() => changeColor('history')}
                        onPointerLeave={() => setHistoryColor('white')}>
                        <div style={{display:'flex'}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 4V10H7" stroke={historyIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M3.51 14.9999C4.15839 16.8403 5.38734 18.4201 7.01166 19.5013C8.63598 20.5825 10.5677 21.1065 12.5157 20.9944C14.4637 20.8823 16.3226 20.1401 17.8121 18.8797C19.3017 17.6193 20.3413 15.9089 20.7742 14.0063C21.2072 12.1037 21.0101 10.1119 20.2126 8.33105C19.4152 6.55019 18.0605 5.07674 16.3528 4.13271C14.6451 3.18868 12.6769 2.82521 10.7447 3.09707C8.81245 3.36892 7.02091 4.26137 5.64 5.63995L1 9.99995" stroke={historyIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <ListItemText className={"widget-title"} sx={{ color: historyIconColor }} primary={'Ratings History'} />
                        </div>
                    </ListItemButton>
                </ListItem>
                <ListItem key={'Forms'} sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <ListItemButton
                        onClick={profileHandler}
                        sx={{ paddingBottom: '0px' }}
                        onPointerOver={() => changeColor('profile')}
                        onPointerLeave={() => setProfileColor('white')}>
                        <div style={{display:'flex'}}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke={profileIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke={profileIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                            <ListItemText className={"widget-title"} sx={{ color: profileIconColor}} primary={'Dashboard'} />
                        </div>
                    </ListItemButton>
                </ListItem>
                <div className={'last-list-item'}>
                    <ListItem key={'Logout'} alignself="flex-end" sx={{ paddingBottom: '0px', paddingTop: '0px' }}>
                        <ListItemButton
                            onClick={logoutHandler}
                            alignself="flex-end"
                            sx={{ paddingBottom: '0px' }}
                            onPointerOver={() => changeColor('logout')}
                            onPointerLeave={() => setLogOutColor('white')}>
                            <div style={{display:'flex'}}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke={logoutIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M16 17L21 12L16 7" stroke={logoutIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M21 12H9" stroke={logoutIconColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                                <ListItemText className={"widget-title"} sx={{ color: logoutIconColor}} primary={'Logout'} />
                            </div>
                        </ListItemButton>
                    </ListItem>
                </div>

            </List>
        </div>
    );

    return (
        <>
            <AppBar
                position="fixed"
                className="appbar"
                sx={{
                    display: { xs: 'none' },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` }
                }}>
                <Toolbar className="mobileDrawerIconHolder">

                    <svg width="152" height="22" viewBox="0 0 152 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M31.7466 7.77816H27.6285V4.11591H39.9351V7.77816H35.9028V18.9596H31.7466V7.77816Z" stroke="#272525" stroke-width="0.6" stroke-miterlimit="10"/>
                        <path d="M41.2982 4.11591H45.4544V9.27087H51.193V4.11591H55.3492V18.9596H51.193V12.9795H45.4544V18.9596H41.2982V4.11591Z" stroke="#272525" stroke-width="0.6" stroke-miterlimit="10"/>
                        <path d="M57.8468 4.11591H69.0953V7.75961H62.003V9.79935H68.6186V13.1186H62.003V15.3715H69.2668V18.9503H57.8373V4.11591H57.8468Z" stroke="#272525" stroke-width="0.6" stroke-miterlimit="10"/>
                        <path d="M80.4391 4.11591H84.2521L90.4674 18.9596H86.0633L85.2721 17.0219H79.4096L78.5993 18.9596H74.2239L80.4391 4.11591ZM84.1854 13.8881L82.3456 9.28941L80.5058 13.8881H84.1854Z" fill="#272525"/>
                        <path d="M91.84 4.11591H98.7797C101.802 4.11591 104.251 6.36889 104.251 9.22451C104.251 11.1437 103.222 12.757 101.649 13.4616L105.462 18.9503H100.686L97.5786 14.3331H96.0057V18.9503H91.8495V4.11591H91.84ZM98.3221 11.0974C99.4184 11.0974 100.114 10.4576 100.114 9.35432C100.114 8.39935 99.4565 7.77816 98.6653 7.77816H95.9962V11.0974H98.3126H98.3221Z" fill="#272525"/>
                        <path d="M106.73 4.11591H117.978V7.75961H110.886V9.79935H117.502V13.1186H110.886V15.3715H118.15V18.9503H106.72V4.11591H106.73Z" fill="#272525"/>
                        <path d="M120.276 4.11591H123.993L130.39 12.2099V4.11591H134.374V18.9596H130.981L124.26 10.5503V18.9596H120.276V4.11591Z" fill="#272525"/>
                        <path d="M141.972 4.11591H145.785L152 18.9596H147.596L146.805 17.0219H140.942L140.132 18.9596H135.756L141.972 4.11591ZM145.718 13.8881L143.878 9.28941L142.038 13.8881H145.718Z" fill="#272525"/>
                        <path d="M3.26014 18.5517L2.74538 17.9954C0.972323 16.0854 0 13.6099 0 11.0139C0 5.43245 4.48984 0.824503 10.2285 0.537086L11.0006 0.5L3.26014 18.5517ZM9.49445 1.55695C4.6519 2.17815 0.972323 6.19272 0.972323 11.0139C0.972323 13.1185 1.67773 15.1305 2.97416 16.7808L9.50398 1.55695H9.49445Z" fill="#272525"/>
                        <path d="M20.0184 12.4788H9.68506L14.4418 1.13974L14.8898 1.32517C15.0233 1.3808 15.1472 1.42716 15.2712 1.49206L15.4332 1.5755L20.0184 12.4881V12.4788ZM11.134 11.5331H18.5694L14.8517 2.66954L11.134 11.5331Z" fill="#272525"/>
                        <path d="M10.7813 21.5C9.43726 21.5 8.11223 21.2589 6.85393 20.7768L6.39636 20.6007L8.58886 15.3715H20.619L20.2186 16.0762C18.3216 19.4232 14.7088 21.5 10.7813 21.5ZM7.67373 20.0629C8.67465 20.3874 9.72323 20.5543 10.7813 20.5543C14.0701 20.5543 17.1205 18.9503 18.9317 16.3172H9.2466L7.67373 20.0629Z" fill="#272525"/>
                    </svg>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 0, display: { sm: 'none' } }}>
                        <svg width="36" height="26" viewBox="0 0 36 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 2.19999C9 1.20592 9.80592 0.399994 10.8 0.399994H34.2C35.1941 0.399994 36 1.20592 36 2.19999C36 3.19407 35.1941 3.99999 34.2 3.99999H10.8C9.80592 3.99999 9 3.194 9 2.19999ZM34.2 11.2H1.8C0.805922 11.2 0 12.006 0 13C0 13.9941 0.805922 14.8 1.8 14.8H34.2C35.1941 14.8 36 13.9941 36 13C36 12.006 35.1941 11.2 34.2 11.2ZM34.2 22H18C17.006 22 16.2 22.8059 16.2 23.8C16.2 24.794 17.006 25.6 18 25.6H34.2C35.1941 25.6 36 24.794 36 23.8C36 22.8059 35.1941 22 34.2 22Z" fill="#605EA6"/>
                        </svg>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="mailbox folders">
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}
                    open>
                    {drawer}
                </Drawer>
                <Drawer
                    container={document.body}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
                    }}>
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}
